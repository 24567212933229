import React from "react";
import SchedulePopup from "../schedulepopup";

const NormalAgenda = ({ viewSummerHours }) => {
  return (
    <SchedulePopup
      btnText="View Two-Day M School Agenda"
      title="TWO-DAY M SCHOOL - NORMAL SCHEDULE"
      subtitle="Sample schedule subject to change."
    >
      <div className="second__modal summer__modal">
        <button className="second__modal__link" onClick={viewSummerHours}>
          View Summer Hours Summer
        </button>
        <p>
          hours for our California location only, valid from June 1 – September
          30.
        </p>
      </div>
      <div className="two_days_schedule">
        <div className="mr_5">
          <h6>DAY ONE</h6>
          <div className="schedule__item">
            <p className="schedule__item__time">7:45 am - 8:15 am</p>
            <p className="schedule__item__event">
              Registration Check-In and <br /> Breakfast
            </p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">8:15 am - 9:30 am</p>
            <p className="schedule__item__event">
              Welcome Introductions and Technical
              <br /> Classroom
            </p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">9:30 am - 12:30 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">12:30 pm - 1:15pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">1:15 pm - 4:30 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">4:40 pm - 5:30 pm</p>
            <p className="schedule__item__event">Driver Debrief</p>
          </div>
        </div>
        <div>
          <h6>DAY TWO</h6>
          <div className="schedule__item">
            <p className="schedule__item__time">8:30 am - 9:00 am</p>
            <p className="schedule__item__event">Arrival</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">9:00 am - 9:45 am</p>
            <p className="schedule__item__event">Class discussion</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">9:00 am - 12:00 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">12:00 pm - 1:00 pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">1:00 pm - 4:00 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">4:10 pm - 4:30 pm</p>
            <p className="schedule__item__event">Closing</p>
          </div>
        </div>
      </div>

    </SchedulePopup>
  );
};
export default NormalAgenda;
