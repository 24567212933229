import React from "react";
import SchedulePopup from "../schedulepopup";

const SummerAgenda = ({ openAgendas, setOpenAgendas, viewNormalHours }) => {

  const closeSummerAgenda = () =>
    setOpenAgendas({ isSummerAgendaOpen: false, isNormalAgendaOpen: false });

  return (
    <SchedulePopup
      open={openAgendas.isSummerAgendaOpen}
      title={<>TWO-DAY M SCHOOL - THERMAL <br /> SUMMER SCHEDULE</>}
      subtitle={
        <div>
          Sample schedule subject to change. <br />
          Summer hours for our California location only, valid from June 1 –
          September 30.
        </div>
      }
      onClose={closeSummerAgenda}
    >
      <div className="second__modal">
        <button className="second__modal__link" onClick={viewNormalHours}>
          View Normal Hours
        </button>
      </div>

      <div className="two_days_schedule">
        <div className="mr_5">
          <h6>DAY ONE</h6>
          <div className="schedule__item">
            <p className="schedule__item__time">6:00 am - 6:30 am</p>
            <p className="schedule__item__event">
              Registration Check-In and Breakfast
            </p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">6:30 am-7:30 am</p>
            <p className="schedule__item__event">
              Welcome Introductions and Technical
              <br /> Classroom
            </p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">7:30 am-12:30 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">12:30 pm-1:30pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
        </div>
        <div>
          <h6>DAY TWO</h6>
          <div className="schedule__item">
            <p className="schedule__item__time">6:00 am-6:30 am</p>
            <p className="schedule__item__event">Arrival</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">6:30 am-7:30 am</p>
            <p className="schedule__item__event">Class discussion</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">7:30 am-12:30 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">12:30 pm-1:30pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
        </div>
      </div>
    </SchedulePopup>
  );
};
export default SummerAgenda;
